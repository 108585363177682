<template>
  <div id="emergencies" v-cloak>
    <van-sticky :offset-top="0">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />事件详情
        </van-col>

        <!-- <van-col
          v-show="
            !emergency.is_approved && emergency.reportable_type !== 'Guide'
          "
          span="14"
          style="text-align: right"
        >
          <div
            v-cloak
            class="header-title btn"
            style="
              text-align: center;
              color: #d52b4d;
              float: right;
              width: 60px;
            "
            @click="onReplytClick('2')"
          >
            拒绝
          </div>
          <div
            class="header-title btn"
            style="
              text-align: center;
              float: right;
              width: 60px;
              margin-right: 15px;
            "
            @click="onReplytClick('1')"
          >
            通过
          </div>
        </van-col> -->
      </van-row>
    </div>
    </van-sticky>
    <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
    <div class="emergencies-detail">
      <div class="detail">
        <van-row>
          <van-col span="16">
            <div class="detail-title">{{ emergency.reporter_name }}</div>
          </van-col>
          <van-col span="8">
            <div style="text-align: right">
              <van-tag
                v-if="emergency.approve_status == '1'"
                size="large"
                mark
                type="primary"
                text-color="#40a9ff"
                color="rgba(64, 169, 255, 0.16)"
                >{{ emergency.approve_status_text }}</van-tag
              >
              <van-tag
                v-if="emergency.approve_status == '2'"
                size="large"
                mark
                type="primary"
                text-color="#D52B4D"
                color="rgba(64, 169, 255, 0.16)"
                >{{ emergency.approve_status_text }}</van-tag
              >
            </div>
          </van-col>
        </van-row>

        <p class="content">{{ emergency.report_content }}</p>
        <van-row v-if="emergency.attachment_url">
          <van-col span="8">
            <van-image fit="contain" :src="emergency.attachment_url" />

          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <div class="date" style="text-align: left">
              {{ emergency.travel_list }}
            </div>
          </van-col>
          <van-col span="12">
            <div class="date" style="text-align: right">
              {{ emergency.created_at }}
            </div>
          </van-col>
        </van-row>
      </div>
      <!-- <div class="reply-title">回复</div> -->
      <div v-if="emergency.replies.length > 0">
         <van-divider content-position="left">回复</van-divider>
        <div
          class="detail"
          v-for="(item, index) in emergency.replies"
          :key="index"
        >
          <div class="detail-title">{{ item.user_name }}</div>
          <div class="content">{{ item.content }}</div>

          <van-row>
            <van-col span="12">
              <div class="date" style="text-align: left">
                {{ item.userable_name }}
              </div>
            </van-col>
            <van-col span="12">
              <div class="date" style="text-align: right">
                {{ item.reply_at }}
              </div>
            </van-col>
          </van-row>
        </div>
        <van-empty v-if="emergency.replies.length == 0" description="暂无回复" />
      </div>
     
      <div
        class="reply-container"
        v-if="(!emergency.is_approved && emergency.reportable_type !== 'Guide')||(!emergency.is_approved && emergency.reportable_type == 'Guide'&&emergency.report_type==0)"
      >
        <textarea
          class="reply-text-area"
          v-model="reply.content"
          placeholder="请填写回复～"
          name=""
          id=""
          cols="10"
          rows="10"
        ></textarea>
      </div>

      <van-row >
        <van-col
          v-cloak
          span="10"
           v-show="(!emergency.is_approved && emergency.reportable_type !== 'Guide')||(!emergency.is_approved && emergency.reportable_type == 'Guide'&&emergency.report_type==0)"
          class="header-title btn"
          style="text-align: center; "
          @click="onReplytClick('1')"
        >
          通过
        </van-col>
        <van-col
          v-cloak
          offset="4"
          span="10"
          class="header-title btn"
          v-show="
            (!emergency.is_approved && emergency.reportable_type !== 'Guide')||(!emergency.is_approved && emergency.reportable_type == 'Guide'&&emergency.report_type==0)
          "
          style="text-align: center; color: #d52b4d"
          @click="onReplytClick('2')"
        >
          拒绝
        </van-col>
      </van-row>
    </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "emergency-item",
  data() {
    return {
      refreshing: false,
      loading: true,
      emergency: {
        replies: [],
        approve_status: "",
        is_approved: "",
        reportable_type: "Guide",
      },
      active: 1,

      reply: {
        content: "",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onItemClick() {
      this.$router.push("/trip/item");
    },
    onReplytClick(approve_status) {
      this.emergency_reply(approve_status);
    },
    async get_emergency(id) {
      const res = await this.$apis.get_emergency(id);
      this.emergency = res.data;
      this.loading = false;
      this.refreshing = false;
    },
    async emergency_reply(approve_status) {
      const id = this.$route.query.id;
      if (this.reply.content == "") {
        Toast("请填写回复内容");
        return false;
      }
      const data = {
        reply: {
          content: this.reply.content,
        },
        approve_status: approve_status,
      };
      const res = await this.$apis.emergency_reply(id, data);
      if (res.status == 200) {
        this.reply.content = "";

        Toast("回复成功");
        this.onRefresh();
        this.$router.go(-1);
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      const id = this.$route.query.id;
      if (!id) {
        Toast("参数错误");
        this.$router.go(-1);

        return false;
      }
      this.get_emergency(id);
    },
  },
  components: {},
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#emergencies {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-bottom: 4rem;
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.emergencies-detail {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
}
.detail {
  /* min-height: 80vh; */
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.4rem;
  margin-bottom: 0.3rem;
  width: 100%;
  white-space: normal;
  word-break: normal;
}
.detail-title {
  text-align: left;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.content {
  font-size: 14px;
  color: #666666;
  width: 100%;
  text-align: left;
  margin-top: 0.5rem;
  word-wrap: break-all;
  min-height: 1rem;
  /* word-break: normal; */
  word-break: break-all;
  /* overflow: hidden; */
}
.date {
  font-size: 14px;
  color: #666666;
  text-align: right;
  margin-top: 0.5rem;
}
.reply-title {
  color: #333;
  font-size: 14px;
  height: 1rem;
  line-height: 1rem;
}
.reply-text-area {
  height: 3rem;
}
.reply-text-area {
  border-radius: 0.2rem;
  background: #ffff;
  width: 100%;
  padding: 0.3rem;
  font-size: 0.3rem;
}
[v-cloak] {
  display: none;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
